import type {ResourceCenterTranslationObj} from '../translation-keys.js';
const resourceCenterText: ResourceCenterTranslationObj = {
    Type : "Type",
    Topic: "Topic",
    Solution: "Solution",
    Industry: "Industry",
    ResourceCenter: "Resource Centre",
    MCResourceCenter: "MasterControl Resource Centre",
    Play:"Play",
    Filter:"Filter",
    Filters:"Filters:",
    LoadingResources: "Loading Resources ...",
    SearchResources: " Search Resources",
    CardView: "Click for the Card View",
    ListView: "Click for the List View",
    NoMatchesFound: "We couldn't find any matches for the selected filters.",
    ClearFilters: "Try clearing your filters or type another search.",
    RemoveFilters: "Remove Filters to see more options.",
    FeaturedContent: "Featured Content",
};
export default resourceCenterText;